import React from "react";
import loader from "../../assets/loader.svg";
import Box from "@mui/material/Box";

const Loader = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)", // Use rgba for transparency
        zIndex: 9999, // Ensure it's above other elements
      }}
    >
      <img
        src={loader}
        alt="Loading..."
        style={{ width: "60px", height: "60px" }}
      />
    </Box>
  );
};

export default Loader;
