import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import axios from "axios";
import ProfileInfo from "../components/ProfileSec/ProfileInfo";
import MediaTabs from "../components/ProfileSec/MediaTabs";
import HeroSec from "../components/HeroSec/HeroSec";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserInfo,
  fetchUserPosts,
  resetUserInfo,
  resetUserPosts,
  resetUserReels,
  resetUserStories,
  restUserHighlightsTray,
  resetUserHighlightMedia,
  addReelsData,
  addPostsData,
  addStoriesData,
  addHighlightsData,
} from "../redux/slice";
import Loader from "../components/Common/Loader";

const Home = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const loaderRef = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.instaData.userInfo);
  const userPosts = useSelector((state) => state.instaData.userPosts);
  const userInfoLoading = useSelector(
    (state) => state.instaData.userInfoLoading
  );
  const userReels = useSelector((state) => state.instaData.userReels);
  const userStories = useSelector((state) => state.instaData.userStories);
  const userHighlightMedia = useSelector(
    (state) => state.instaData.userHighlightMedia
  );
  const handleInputChange = (event) => {
    setUsername(event.target.value);
  };

  useEffect(() => {
    if (userInfo && loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userInfo]);

  const handleSearch = async () => {
    // dispatch(resetUserInfo());
    if (!username) {
      setError("Username cannot be empty.");
      return;
    }

    dispatch(resetUserInfo()); // Optional: reset previous user info
    dispatch(resetUserPosts()); // Clear previous posts
    dispatch(resetUserReels()); // Clear previous reels
    dispatch(resetUserStories()); // Clear previous stories
    dispatch(restUserHighlightsTray()); //Clear previous Highlightlight tray
    dispatch(resetUserHighlightMedia()); //Clear previous Highlighlight Media

    dispatch(fetchUserInfo(username));
    // dispatch(fetchUserPosts({ username, count: 12, next_max_id: "" }));
  };

  useEffect(() => {
    if (userPosts?.data?.items) {
      const newPostsUrls = userPosts.data.items
        .map((item) => {
          if (item.media_type === 8) {
            return item.carousel_media.map((carouselItem) => ({
              imageUrl:
                carouselItem.image_versions2?.candidates?.[1]?.url ||
                carouselItem.image_versions2?.candidates?.[0]?.url ||
                null,
              videoUrl: carouselItem.video_versions?.[0]?.url || null,
              media_type: carouselItem.media_type,
              like_count: item?.like_count,
              taken_at: item?.taken_at,
              comment_count: item?.comment_count,
            }));
          } else if (item.media_type === 1) {
            return {
              imageUrl:
                item.image_versions2?.candidates?.[1]?.url ||
                item.image_versions2?.candidates?.[0]?.url ||
                null,
              videoUrl: null,
              media_type: item.media_type,
              like_count: item?.like_count,
              taken_at: item?.taken_at,
              comment_count: item?.comment_count,
            };
          } else if (item.media_type === 2) {
            return {
              imageUrl:
                item.image_versions2?.candidates?.[1]?.url ||
                item.image_versions2?.candidates?.[0]?.url ||
                null,
              videoUrl: item.video_versions?.[0]?.url || null,
              media_type: item.media_type,
              like_count: item?.like_count,
              taken_at: item?.taken_at,
              comment_count: item?.comment_count,
            };
          }
          return null;
        })
        .flat()
        .filter(Boolean);
      dispatch(addPostsData(newPostsUrls));
    }
  }, [userPosts]);

  useEffect(() => {
    if (userReels?.data?.items) {
      const newReelsUrls = userReels.data.items
        .map((item) => {
          if (item.media.media_type === 2) {
            return {
              videoUrl: item?.media?.video_versions?.[0].url || null,
              imageUrl:
                item?.media?.image_versions2?.candidates?.[1]?.url ||
                item?.media?.image_versions2?.candidates?.[0]?.url ||
                null,
              media_type: item?.media?.media_type,
              like_count: item?.media?.like_count,
              comment_count: item?.media?.comment_count,
              taken_at: item?.media?.taken_at,
            };
          }
          return null;
        })
        .filter(Boolean);
      dispatch(addReelsData(newReelsUrls));
    }
  }, [userReels]);

  useEffect(() => {
    if (userStories?.data?.reel?.items) {
      const newStoriesUrls = userStories.data.reel.items
        .map((item) => {
          return {
            videoUrl: item?.video_versions?.[0].url || null,
            imageUrl:
              item?.image_versions2?.candidates?.[1]?.url ||
              item?.image_versions2?.candidates?.[0]?.url ||
              null,
            media_type: item?.media_type,
            taken_at: item?.taken_at,
          };
        })
        .filter(Boolean);
      dispatch(addStoriesData(newStoriesUrls));
    }
  }, [userStories]);

  useEffect(() => {
    if (userHighlightMedia) {
      const highlightId = userHighlightMedia?.data?.reels_media?.[0]?.id;
      if (highlightId) {
        const newHighlightMediaUrls =
          userHighlightMedia?.data?.reels_media?.[0].items.map((item) => {
            return {
              videoUrl: item?.video_versions?.[0].url || null,
              imageUrl:
                item?.image_versions2?.candidates?.[1]?.url ||
                item?.image_versions2?.candidates?.[0]?.url ||
                null,
              media_type: item?.media_type || null,
              taken_at: item?.taken_at,
            };
          });

        dispatch(addHighlightsData({ [highlightId]: newHighlightMediaUrls }));
      }
    }
  }, [userHighlightMedia]);

  return (
    <Box sx={{ minHeight: "100vh", minWidth: "100%", overflow: "hidden" }}>
      <HeroSec
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        username={username}
        setUsername={setUsername}
        userInfo={userInfo}
        error={error}
        setError={setError}
      />

      <div ref={loaderRef}>
        {userInfoLoading ? (
          <Loader />
        ) : (
          userInfo && <ProfileInfo userInfo={userInfo} />
        )}
      </div>
    </Box>
  );
};

export default Home;
