import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px 0",
        backgroundColor: "#333",
        color: "white",
        width: "100%",
      }}
    >
      <Typography
        sx={{ fontSize: "0.6rem", textAlign: "center", marginBottom: "8px" }}
      >
        © Instagram Story Viewer. {new Date().getFullYear()}. Asinva is not
        affiliated with Instagram™. We do not host any Instagram content. All
        rights belong to their respective owners.
      </Typography>

      <Box sx={{ display: "flex", gap: "10px" }}>
        <Link
          href="/terms-of-use"
          color="inherit"
          underline="hover"
          sx={{ fontSize: "0.6rem" }}
        >
          Terms of Use
        </Link>
        <Link
          href="/privacy-policy"
          color="inherit"
          underline="hover"
          sx={{ fontSize: "0.6rem" }}
        >
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
