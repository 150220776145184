import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#333",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderBottom: "2px solid #333", // Subtle border
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              height: 30,
              width: "auto",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              background:
                "linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
              display: "block",
              backgroundClip: "text",
              gap: 1,
            }}
          >
            {new URL(process.env.REACT_APP_API_BASE_URL).hostname.toUpperCase()}
          </Typography>
        </Box>

        {/* For mobile screens */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          sx={{
            display: { xs: "block", md: "none" }, // Show only on mobile
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* Dropdown menu for mobile */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              mt: 1.5, // Spacing between button and menu
              boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
              backgroundColor: "#fff",
              borderRadius: 1, // Rounded corners
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem component={Link} to="/" onClick={handleMenuClose}>
            Home
          </MenuItem>
          <MenuItem component={Link} to="/contact" onClick={handleMenuClose}>
            Contact
          </MenuItem>
        </Menu>
        {/* For desktop screens */}
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Button
            color="inherit"
            component={Link}
            to="/"
            sx={{
              mx: 1,
              fontWeight: "bold",
              textTransform: "none", // Disable uppercase text
              "&:hover": {
                backgroundColor: "#555", // Subtle hover effect
              },
            }}
          >
            Home
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/contact"
            sx={{
              mx: 1,
              fontWeight: "bold",
              textTransform: "none", // Disable uppercase text
              "&:hover": {
                backgroundColor: "#555", // Subtle hover effect
              },
            }}
          >
            Contact
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
