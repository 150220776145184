import React from "react";
import {
  Box,
  Avatar,
  Typography,
  Grid,
  Link,
  Divider,
  Container,
} from "@mui/material";
import MediaTabs from "./MediaTabs";

const ProfileInfo = ({ userInfo }) => {
  const mediaUrl = encodeURIComponent(userInfo?.data?.profile_pic_url);
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // Format as millions
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Format as thousands
    }
    return num; // Return the number as is if less than 1000
  };

  return (
    <Container
      id="profile"
      sx={{
        // padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding for different screen sizes
        backgroundColor: "#1976d2", // Background color
        minWidth: "100%", // Full width of the viewport
        minHeight: "100vh", // Full viewport height
        display: "flex", // Flexbox layout for container
        flexDirection: "column",
        justifyContent: "flex-start", // Space out the content vertically
        maxWidth: { xs: "100vw", md: "90vw" }, // Full width on small screens, 90% on larger screens
        margin: "0 auto", // Center the content horizontally
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: "whitesmoke",
            textAlign: "center",
            marginBottom: "16px",
            fontWeight: "500",
          }}
        >
          Search Result
        </Typography>
        <Box
          sx={{
            backgroundColor: "rgba(0,0,0,0.1)",
            color: "#fff",
            padding: 2,
            borderRadius: 2,
            textAlign: "center",
            maxWidth: 600,
            margin: "auto",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Avatar
                alt="Profile Image"
                src={`${process.env.REACT_APP_API_BASE_URL}/fetch-media?mediaUrl=${mediaUrl}`}
                sx={{ width: 80, height: 80, margin: "auto" }}
              />
              <Typography
                variant="h7"
                sx={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Link href="#" color="inherit" underline="none">
                  @{userInfo?.data?.username}
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={8} textAlign="left">
              <Typography variant="h6">{userInfo?.data?.full_name}</Typography>

              <Box
                justifyContent="space-between"
                sx={{ display: "flex", marginTop: "10px" }}
              >
                <Typography variant="body1">
                  {userInfo?.data?.media_count}{" "}
                  <span style={{ color: "#bbb" }}>posts</span>
                </Typography>
                <Typography variant="body1">
                  {formatNumber(userInfo?.data?.follower_count)}{" "}
                  <span style={{ color: "#bbb" }}>followers</span>
                </Typography>
                <Typography variant="body1">
                  {formatNumber(userInfo?.data?.following_count)}{" "}
                  <span style={{ color: "#bbb" }}>following</span>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            display="block"
            sx={{ marginTop: 2, color: "#bbb", textAlign: "left" }}
          >
            {userInfo?.data?.biography}
          </Typography>
          <Divider sx={{ marginY: 2, backgroundColor: "#bbb" }} />
        </Box>
      </Box>

      <MediaTabs />
    </Container>
  );
};

export default ProfileInfo;
