import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/api";

export const fetchUserInfo = createAsyncThunk(
  "fetchUserInfo",
  async (username) => {
    try {
      const response = await api.get(`/user_info/${username}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserPosts = createAsyncThunk(
  "fetchUserPosts",
  async ({ username, count, next_max_id }) => {
    try {
      const response = await api.get(
        `/user_posts/${username}/${count}/${next_max_id || ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//reels
export const fetchUserReels = createAsyncThunk(
  "fetchUserReels",
  async ({ username, max_id }) => {
    try {
      const response = await api.get(`/user_reels/${username}/${max_id || ""}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//story
export const fetchUserStories = createAsyncThunk(
  "fetchUserStories",
  async ({ username }) => {
    try {
      const response = await api.get(`/user_stories/${username}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//highlights tray
export const fetchUserHighlightsTray = createAsyncThunk(
  "fetchUserHighlightsTray",
  async ({ username }) => {
    try {
      const response = await api.get(`/user_highlight_tray/${username}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//highlights media
export const fetchUserHighlightMedia = createAsyncThunk(
  "fetchUserHighlightMedia",
  async (highlight_id) => {
    try {
      const response = await api.get(`highlight_media/${highlight_id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const instaSlice = createSlice({
  name: "instaData",
  initialState: {
    userInfo: null,
    userInfoLoading: false,
    userPosts: null,
    userPostsLoading: false,
    userReels: null,
    userReelsLoading: false,
    userStories: null,
    userStoriesLoading: false,
    userHighlightsTray: null,
    userHighlightsTrayLoading: false,
    userHighlightMedia: null,
    userHighlightMediaLoading: false,
    postsData: [],
    reelsData: [],
    storiesData: [],
    highlightsData: {},
    reelsTabOpened: false,
    postsTabOpened: false,
    storiesTabOpened: false,
    highlightsTabOpened: false,
    selectedHighlightsTray: null,
  },
  reducers: {
    resetUserInfo: (state) => {
      state.userInfo = null;
    },
    resetUserPosts: (state) => {
      state.userPosts = null;
    },
    resetUserReels: (state) => {
      state.userReels = null;
    },
    resetUserStories: (state) => {
      state.userStories = null;
    },
    restUserHighlightsTray: (state) => {
      state.userHighlightsTray = null;
    },
    resetUserHighlightMedia: (state) => {
      state.userHighlightMedia = null;
    },
    addPostsData: (state, action) => {
      state.postsData = [...state.postsData, ...action.payload];
    },
    addReelsData: (state, action) => {
      state.reelsData = [...state.reelsData, ...action.payload];
    },
    addStoriesData: (state, action) => {
      state.storiesData = [...state.storiesData, ...action.payload]; // check
    },
    addHighlightsData: (state, action) => {
      const newData = action.payload;
      state.highlightsData = { ...state.highlightsData, ...newData };
    },
    setReelsTabOpened: (state, action) => {
      state.reelsTabOpened = action.payload;
    },
    setPostsTabOpened: (state, action) => {
      state.postsTabOpened = action.payload;
    },
    setStoriesTabOpened: (state, action) => {
      state.storiesTabOpened = action.payload;
    },
    setHighlightsTabOpened: (state, action) => {
      state.highlightsTabOpened = action.payload;
    },
    setSelectedHighlightsTray: (state, action) => {
      state.selectedHighlightsTray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.userInfoLoading = false;
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.userInfoLoading = false;
      })
      .addCase(fetchUserPosts.pending, (state) => {
        state.userPostsLoading = true;
      })
      .addCase(fetchUserPosts.fulfilled, (state, action) => {
        state.userPosts = action.payload;
        state.userPostsLoading = false;
      })
      .addCase(fetchUserPosts.rejected, (state) => {
        state.userPostsLoading = false;
      })
      .addCase(fetchUserReels.pending, (state) => {
        state.userReelsLoading = true;
      })
      .addCase(fetchUserReels.fulfilled, (state, action) => {
        state.userReels = action.payload;
        state.userReelsLoading = false;
      })
      .addCase(fetchUserReels.rejected, (state) => {
        state.userReelsLoading = false;
      })
      .addCase(fetchUserStories.pending, (state) => {
        state.userStoriesLoading = true;
      })
      .addCase(fetchUserStories.fulfilled, (state, action) => {
        state.userStories = action.payload;
        state.userStoriesLoading = false;
      })
      .addCase(fetchUserStories.rejected, (state) => {
        state.userStoriesLoading = false;
      })
      .addCase(fetchUserHighlightsTray.pending, (state) => {
        state.userHighlightsTrayLoading = true;
      })
      .addCase(fetchUserHighlightsTray.fulfilled, (state, action) => {
        state.userHighlightsTray = action.payload;
        state.userHighlightsTrayLoading = false;
      })
      .addCase(fetchUserHighlightsTray.rejected, (state) => {
        state.userHighlightsTrayLoading = false;
      })
      .addCase(fetchUserHighlightMedia.pending, (state) => {
        state.userHighlightMediaLoading = true;
      })
      .addCase(fetchUserHighlightMedia.fulfilled, (state, action) => {
        state.userHighlightMedia = action.payload;
        state.userHighlightMediaLoading = false;
      })
      .addCase(fetchUserHighlightMedia.rejected, (state) => {
        state.userHighlightMediaLoading = false;
      });
  },
});

export const {
  resetUserInfo,
  resetUserPosts,
  resetUserReels,
  resetUserStories,
  restUserHighlightsTray,
  resetUserHighlightMedia,
  addPostsData,
  addReelsData,
  addStoriesData,
  addHighlightsData,
  setReelsTabOpened,
  setPostsTabOpened,
  setStoriesTabOpened,
  setHighlightsTabOpened,
  setSelectedHighlightsTray,
} = instaSlice.actions;
export default instaSlice.reducer;
