import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";

const TermsOfUse = () => {
  return (
    <Container
      maxWidth="100%"
      style={{ padding: "2rem", backgroundColor: "#1976d2" }}
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ color: "white", textDecoration: "underline 2px " }}
      >
        Terms Of Use
      </Typography>

      <Box mt={3}>
        <Typography
          variant="h7"
          gutterBottom
          align="left"
          style={{ color: "white" }}
        >
          These Terms of Service (“Terms”) govern your access to and use of the
          Asinva website (“Asinva” or the “Service”). These Terms apply to all
          users and others who access the Service (“Users”). Please read these
          Terms carefully, and contact us if you have any questions. By
          accessing or using Asinva, you agree to be bound by these Terms and
          our Privacy Policy.
          <br />
          <br />
          Asinva may, in its sole discretion, modify or update these Terms from
          time to time, and you should review this page periodically. Your
          continued use of the Service after any such change means your
          acceptance of the new Terms. If you do not agree with the Terms, do
          not use or access (or continue to access) the Service.
          <br />
          <br />
          **General Terms:** You may use the Service only if you can form a
          binding contract with Asinva, and only in compliance with these Terms
          and all applicable local, state, national, and international laws,
          rules, and regulations. By using the Service, you represent and
          warrant that you have the full right, power, and authority to enter
          into these Terms and to fully perform all of your obligations
          hereunder. You must be at least of the age of sixteen (16) years old
          to use the Service unless you are under the permitted age and your use
          of the Service is directly supervised by your parent or guardian or
          another authorized adult who agrees to be bound by these Terms.
          <br />
          <br />
          Any use or access to the Service by anyone under the permitted age who
          is not directly supervised by an authorized adult is strictly
          prohibited. All or part of the Service may not be available to Users
          from certain countries for technical reasons or due to local
          regulations. Asinva may, without prior notice, change the Service,
          stop providing the Service or features of the Service, or create usage
          limits for the Service.
          <br />
          <br />
          **Prohibited Use:** You agree not to engage in any of the following
          prohibited activities: <br />
          (1) copying, distributing, or disclosing any part of the Service,
          including without limitation by any automated or non-automated
          “scraping”;
          <br />
          (2) using any automated system, including without limitation “robots,”
          “spiders,” “offline readers,” etc., to access the Service; <br />
          (3) transmitting spam, chain letters, or other unsolicited emails;{" "}
          <br />
          (4) attempting to interfere with, compromise the system integrity or
          security, or decipher any transmissions to or from the servers running
          the Service;
          <br /> (5) taking any action that imposes, or may impose at our sole
          discretion, an unreasonable or disproportionately large load on our
          infrastructure;
          <br />
          (6) uploading invalid data, viruses, worms, or other software agents
          through the Service; <br />
          (7) using the Service for any commercial solicitation purposes; <br />
          (8) interfering with the proper working of the Service; <br />
          (9) accessing any content on the Service through any technology or
          means other than those provided or authorized by the Service; <br />
          (10) bypassing the measures we may use to prevent or restrict access
          to the Service; <br />
          (11) copying, imitating, or using, in whole or in part, the look and
          feel of the Service (including but not limited to all page headers,
          custom graphics, button icons, and scripts) without the prior written
          consent of the Service;
          <br />
          (12) framing or hotlinking to the Service or any content other than
          your own without the prior written consent of the Service. Asinva
          reserves the right, at its own discretion, to terminate the user’s
          access to the Service for multiple infringements.
          <br />
          <br />
          **Intellectual Property:** All rights, title, and interest in and to
          the Service, including our existing or future applications, our APIs,
          databases, and other parts of the Service are and will remain the
          exclusive property of Asinva. The use of Asinva's name or any other
          distinctive brand features is prohibited. Subject to these Terms, we
          grant you a limited, non-exclusive, non-transferable, and revocable
          license to use our Service. Asinva reserves all rights not expressly
          granted herein in the Service and may terminate this license at any
          time for any reason or no reason.
          <br />
          <br />
          By using Asinva, you understand and acknowledge that the Service does
          not have any control over nor are we responsible for any third-party
          content. Third-parties retain all rights to third-party content and
          are therefore responsible for protecting their rights as they deem
          necessary. It is the User’s responsibility to make sure that the User
          has all required permissions to access and download third-party
          content. The content that we deliver to you is only accessible per
          your request, and a copy of such content is not maintained on our
          system for longer than is reasonably necessary for you to download the
          copy.
          <br />
          <br />
          Asinva does not sell access or subscription services to copyrighted
          works; own or operate servers which store access to copyrighted
          material; share or communicate copyrighted material to the public; or
          provide access to privately available media or media available on a
          payment basis.
          <br />
          <br />
          **Limitation of Liability and No Warranty:** The Service and its
          content are provided on an “as is” and “as available” basis. Asinva
          makes no representations or warranties about the suitability,
          reliability, availability, timeliness, security, lack of errors, or
          accuracy of the Service or its content, and expressly disclaims any
          warranties or conditions, including the implied warranties of
          merchantability, fitness for a particular purpose, title, and
          non-infringement. Asinva makes no warranty that you will obtain
          specific results from use of the Service. Your use of the Service is
          entirely at your own risk.
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, Asinva SHALL NOT BE LIABLE FOR
          ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
          OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
          INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
          LOSSES, RESULTING FROM <br />
          (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
          SERVICE; <br />
          (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE,
          INCLUDING WITHOUT LIMITATION ANY DEFAMATORY, OFFENSIVE OR ILLEGAL
          CONDUCT OF OTHER USERS OR THIRD PARTIES; OR
          <br /> (C) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
          TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF
          Asinva FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED ONE HUNDRED U.S.
          DOLLARS (the U.S. $100.00). Some jurisdictions do not allow the
          exclusion of implied warranties, so some of the above exclusions may
          not apply to you.
          <br />
          <br />
          **Indemnity:** You agree to defend, indemnify, and hold harmless
          Asinva and its subsidiaries, agents, licensors, managers, and other
          affiliated companies, and their employees, contractors, agents,
          officers, and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs, or debt, and expenses arising
          from: <br />
          (1) your use of and access to the Service, including any data or
          content transmitted or received by you; <br />
          (2) your violation of any part of this Terms, including without
          limitation your breach of any of the representations and warranties
          above; <br />
          (3) your violation of any third-party right, applicable law, rule, or
          regulation.
          <br />
          <br />
          **Third-Party Links:** The Service may contain links to third-party
          websites, advertisers, services, special offers, or other events or
          activities that are not owned or controlled by Asinva. We don’t
          endorse or assume any responsibility for any such third-party sites,
          information, materials, products, or services. If you access any
          third-party website, service, or content from the Service, you do so
          at your own risk and you agree that Asinva has no liability arising
          from your use of or access to any third-party website, service, or
          content.
          <br />
          <br />
          **Miscellaneous:** These Terms, and any rights and licenses granted
          hereunder, may not be transferred or assigned by you but may be
          assigned by Asinva without restriction. Any attempted transfer or
          assignment in violation hereof shall be null and void. If any
          provision of these Terms is deemed invalid, then that provision will
          be limited or eliminated to the minimum extent necessary, and the
          remaining provisions of these Terms will remain in full force and
          effect. No waiver of any term of these Terms shall be deemed a further
          or continuing waiver of such term or any other term, and Asinva’s
          failure to assert any right or provision under these Terms shall not
          constitute a waiver of such right or provision.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfUse;
