import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Fab, tabsClasses } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import PostsTab from "./Posts/PostsTab";
import ReelsTab from "./Reels/ReelsTab";
import StoriesTab from "./Stories/StoriesTab";
import HighlightsTab from "./Highlights/HighlightsTab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%", height: "100%" }}
    >
      {value === index && (
        <Box
          sx={{
            py: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const MediaTabs = () => {
  const [value, setValue] = useState(0);
  const [showScroll, setShowScroll] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ width: "100%", padding: "15px 0" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="profile tabs"
        variant="scrollable"
        sx={{
          width: "100%",
          borderBottom: "1px solid #fff",
          "& .MuiTabs-indicator": {
            backgroundColor: "#fff",
          },
          "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
          },
          "& .MuiTab-root": {
            color: "white",
            fontWeight: 600,
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "10px 10px 0 0 ",
            },
          },
        }}
      >
        <Tab
          label="POSTS"
          disableRipple
          sx={{ color: "white", width: "22%" }}
        />
        <Tab
          label="REELS"
          disableRipple
          sx={{ color: "white", width: "22%" }}
        />
        <Tab
          label="STORIES"
          disableRipple
          sx={{ color: "white", width: "22%" }}
        />
        <Tab
          label="HIGHLIGHTS"
          disableRipple
          sx={{ color: "white", width: "34%" }}
        />
      </Tabs>

      {/* posts */}
      <TabPanel value={value} index={0}>
        <PostsTab />
      </TabPanel>

      {/* reels */}
      <TabPanel value={value} index={1}>
        <ReelsTab />
      </TabPanel>
      {/* stories */}
      <TabPanel value={value} index={2}>
        <StoriesTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <HighlightsTab />
      </TabPanel>

      {showScroll && (
        <Fab
          color="primary"
          size="small"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "10px",
            right: "0px",
            zIndex: 1000,
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <ArrowUpward />
        </Fab>
      )}
    </Box>
  );
};

export default React.memo(MediaTabs);
