import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Posts from "./Posts";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPosts, setPostsTabOpened } from "../../../redux/slice";

const PostsTab = () => {
  const dispatch = useDispatch();
  const next_max_id = useSelector(
    (state) => state?.instaData?.userPosts?.data?.next_max_id
  );
  const username = useSelector(
    (state) => state?.instaData?.userInfo?.data?.username
  );
  const postsMediaUrls = useSelector((state) => state?.instaData?.postsData);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:1024px)");
  const isFirstRender = useRef(true);
  const reelsTabOpened = useSelector(
    (state) => state?.instaData?.reelsTabOpened
  );

  const handleLoadPosts = () => {
    if (username) {
      dispatch(
        fetchUserPosts({
          username,
          count: 6,
          next_max_id,
        })
      );
    }
  };

  useEffect(() => {
    if (isFirstRender.current && !reelsTabOpened && username) {
      handleLoadPosts();
      dispatch(setPostsTabOpened(true));
      isFirstRender.current = false;
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {postsMediaUrls.map((media, index) => (
          <Box
            key={index}
            sx={{
              width: isMobile
                ? "100%" 
                : isTablet
                ? "calc(50% - 10px)"
                : "calc(33.33% - 10px)",
            }}
          >
            <Posts
              imageUrl={media.imageUrl}
              videoUrl={media.videoUrl}
              media_type={media.media_type}
              like_count={media.like_count}
              taken_at={media.taken_at}
              comment_count={media.comment_count}
            />
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        color="success"
        sx={{ width: "100%", marginTop: "20px" }}
        onClick={handleLoadPosts}
      >
        Load More Posts
      </Button>
    </>
  );
};

export default PostsTab;
