import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserReels, setReelsTabOpened } from "../../../redux/slice";
import Reels from "./Reels";

const ReelsTab = () => {
  const dispatch = useDispatch();
  const reelsMediaUrls = useSelector((state) => state?.instaData?.reelsData);
  const reelsTabOpened = useSelector(
    (state) => state?.instaData?.reelsTabOpened
  );
  const username = useSelector(
    (state) => state?.instaData?.userInfo?.data?.username
  );
  const max_id = useSelector(
    (state) => state?.instaData?.userReels?.data?.paging_info?.max_id
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:1024px)");
  const isFirstRender = useRef(true);

  const handleLoadReels = () => {
    if (username) {
      dispatch(fetchUserReels({ username, max_id }));
    }
  };

  useEffect(() => {
    if (isFirstRender.current && !reelsTabOpened && username) {
      handleLoadReels();
      dispatch(setReelsTabOpened(true));
      isFirstRender.current = false;
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {reelsMediaUrls.map((media, index) => (
          <Box
            key={index}
            sx={{
              width: isMobile
                ? "100%"
                : isTablet
                ? "calc(50% - 10px)"
                : "calc(33.33% - 10px)",
            }}
          >
            <Reels
              videoUrl={media.videoUrl}
              media_type={media.media_type}
              reelsMediaUrls={reelsMediaUrls}
              imageUrl={media.imageUrl}
              like_count={media.like_count}
              taken_at={media.taken_at}
              comment_count={media.comment_count}
            />
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        color="success"
        sx={{ width: "100%", marginTop: "20px" }}
        onClick={handleLoadReels}
      >
        Load More Reels
      </Button>
    </>
  );
};

export default ReelsTab;
