// src/ContactInfo.js
import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";

const ContactInfo = () => {
  return (
    <Container
      maxWidth={false} // Ensures full width by disabling maxWidth
      style={{
        width: "100%", // Full width
        minHeight: "100vh", // Full viewport height
        padding: "2rem",
        backgroundColor: "#1976d2",
        color: "white",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ paddingTop: "2rem" }}
      >
        Contact
      </Typography>

      <Box mt={3} sx={{ textAlign: "center" }}>
        <Typography variant="h7" gutterBottom align="center ">
          For any queries or complaints please email us at
          <br />
          <a href=""> abc@asinva.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default ContactInfo;
