import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserStories, setStoriesTabOpened } from "../../../redux/slice";
import Stories from "./Stories";

const StoriesTab = () => {
  const dispatch = useDispatch();
  const storiesMediaUrls = useSelector(
    (state) => state?.instaData?.storiesData
  );
  const storiesTabOpened = useSelector(
    (state) => state?.instaData?.storiesTabOpened
  );
  const username = useSelector(
    (state) => state?.instaData?.userInfo?.data?.username
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:1024px)");
  const isFirstRender = useRef(true);

  const handleLoadStories = () => {
    if (username) {
      dispatch(fetchUserStories({ username }));
    }
  };

  useEffect(() => {
    if (isFirstRender.current && !storiesTabOpened && username) {
      handleLoadStories();
      dispatch(setStoriesTabOpened(true));
      isFirstRender.current = false;
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      {storiesMediaUrls.map((media, index) => (
        <Box
          key={index}
          sx={{
            width: isMobile
              ? "100%" 
              : isTablet
              ? "calc(50% - 10px)"
              : "calc(33.33% - 10px)",
          }}        >
          <Stories
            videoUrl={media.videoUrl}
            media_type={media.media_type}
            imageUrl={media.imageUrl}
            taken_at={media.taken_at}
          />
        </Box>
      ))}
    </Box>
  );
};

export default StoriesTab;
