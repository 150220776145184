import { Avatar, Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserHighlightMedia,
  fetchUserHighlightsTray,
  setHighlightsTabOpened,
  setSelectedHighlightsTray,
} from "../../../redux/slice";
import Stories from "../Stories/Stories";
import Highlights from "./Highlights";

const HighlightsTab = () => {
  const dispatch = useDispatch();
  const highlightsTrayMediaUrls = useSelector(
    (state) => state?.instaData?.highlightsTrayMediaUrls
  );
  const userHighlightsTray = useSelector(
    (state) => state?.instaData?.userHighlightsTray
  );

  const highlightsTabOpened = useSelector(
    (state) => state?.instaData?.highlightsTabOpened
  );
  const username = useSelector(
    (state) => state?.instaData?.userInfo?.data?.username
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:1024px)");
  const isFirstRender = useRef(true);

  const highlightsData = useSelector(
    (state) => state?.instaData?.highlightsData
  );
  const selectedHighlightsTray = useSelector(
    (state) => state?.instaData?.selectedHighlightsTray
  );

  let highlightsMediaUrls;
  if (selectedHighlightsTray) {
    highlightsMediaUrls = highlightsData?.[selectedHighlightsTray];
  } else highlightsMediaUrls = [];

  const handleLoadHighlightsTray = () => {
    if (username) {
      dispatch(fetchUserHighlightsTray({ username }));
    }
  };

  const handleHighlightMedia = (highlight_id) => {
    const numberPart = highlight_id.match(/\d+/)[0];
    dispatch(setSelectedHighlightsTray(highlight_id));

    if (!(highlight_id in highlightsData)) {
      dispatch(fetchUserHighlightMedia(numberPart));
    }
  };

  useEffect(() => {
    if (isFirstRender.current && !highlightsTabOpened && username) {
      handleLoadHighlightsTray();
      dispatch(setHighlightsTabOpened(true));
      isFirstRender.current = false;
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: "9px 2px",
          width: "100%",
          backgroundColor: "#333",
          borderRadius: "9px",
          border: "2px solid white",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            flexWrap: "nowrap",
            justifyContent: "space-evenly",
            overflowX: "auto",
            padding: "10px",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          {userHighlightsTray?.data?.tray?.map((tray, index) => (
            <Avatar
              key={index}
              alt="Highlight"
              src={`${
                process.env.REACT_APP_API_BASE_URL
              }/fetch-media?mediaUrl=${encodeURIComponent(
                tray.cover_media?.cropped_image_version?.url || null
              )}`}
              sx={{
                width: 70,
                height: 70,
                border: "3px solid #e0e0e0",
                borderRadius: "50%",
                cursor: "pointer",
                transition: "transform 0.3s, border-color 0.3s",
                "&:hover": {
                  transform: "scale(1.1)",
                  borderColor: "#007bff",
                },
                ...(selectedHighlightsTray === tray?.id && {
                  transform: "scale(1.1)",
                  borderColor: "#007bff",
                }),
              }}
              onClick={() => {
                handleHighlightMedia(tray?.id);
              }}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          // justifyContent: "space-between",
          marginTop: "30px",
          height: "100%",
        }}
      >
        {highlightsMediaUrls?.map((highlight, index) => {
          return (
            <Box
              sx={{
                width: isMobile
                  ? "100%"
                  : isTablet
                  ? "calc(50% - 10px)"
                  : "calc(33.33% - 10px)",
              }}
              key={index}
            >
              <Highlights
                videoUrl={highlight.videoUrl}
                imageUrl={highlight.imageUrl}
                media_type={highlight.media_type}
                taken_at={highlight.taken_at}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default HighlightsTab;
