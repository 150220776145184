import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const MAX_RETRIES = 2;

// Map to track pending requests
const pendingRequests = new Map();

const getRequestKey = (config) => 
  `${config.method}:${config.url}:${JSON.stringify(config.params || config.data)}`;

api.interceptors.request.use((config) => {
  const requestKey = getRequestKey(config);

  // Check if the same request is already pending
  if (pendingRequests.has(requestKey)) {
    return Promise.reject(new axios.Cancel(`Duplicate request: ${requestKey}`));
  }

  // Add the request to pending requests
  pendingRequests.set(requestKey, true);

  return config;
});

api.interceptors.response.use(
  (response) => {
    // Remove the completed request from pending requests
    const requestKey = getRequestKey(response.config);
    pendingRequests.delete(requestKey);

    return response;
  },
  async (error) => {
    const { config } = error;

    // Remove the request from pending requests if it failed
    const requestKey = getRequestKey(config);
    pendingRequests.delete(requestKey);

    // Handle retries
    if (!config.__retryCount) {
      config.__retryCount = 0;
    }

    if (config.__retryCount < MAX_RETRIES) {
      config.__retryCount += 1;
      return api(config); // Retry the request
    }

    // If retries are exceeded, return the error
    return Promise.reject(error);
  }
);

export default api;
