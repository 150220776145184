import React, { useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardMedia,
  useMediaQuery,
  IconButton,
  Box,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Fullscreen from "@mui/icons-material/Fullscreen";
import loader from "../../../assets/blackLoader.svg";
import { PlayArrow } from "@mui/icons-material";
import HighlightsModal from "./HighlightsModal";
import { formatTimestamp } from "../../../utils.js/helpers";
import { useSelector } from "react-redux";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import useDownload from "../../../hooks/useDownload";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Highlights = ({ videoUrl, imageUrl, media_type, taken_at }) => {
  const { downloading, downloadProgress, handleDownload } = useDownload();
  const [open, setOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const highlightsData = useSelector(
    (state) => state?.instaData?.highlightsData
  );
  const selectedHighlightsTray = useSelector(
    (state) => state?.instaData?.selectedHighlightsTray
  );

  let highlightsMediaUrls;
  if (selectedHighlightsTray) {
    highlightsMediaUrls = highlightsData?.[selectedHighlightsTray];
  } else highlightsMediaUrls = [];

  const handleMediaClick = (url) => {
    setMediaLoading(true);
    setOpen(true);

    setCurrentMediaIndex(
      highlightsMediaUrls.findIndex((image) => image.imageUrl === url)
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          width: isMobile ? "100%" : "80%",
          padding: 0.2,
          textAlign: "center",
          backgroundColor: "#fff",
          borderRadius: "9px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
          position: "relative",
        }}
      >
        {imgLoading && (
          <CardMedia
            component="img"
            height="250px"
            image={loader}
            alt="loader"
            sx={{
              borderRadius: "8px",
              cursor: "pointer",
            }}
          />
        )}
        <CardMedia
          key={imageUrl}
          component="img"
          height="250px"
          image={`${
            process.env.REACT_APP_API_BASE_URL
          }/fetch-media?mediaUrl=${encodeURIComponent(imageUrl)}`}
          alt="Image"
          sx={{
            borderRadius: "8px",
            cursor: "pointer",
            display: imgLoading ? "none" : "block",
          }}
          onClick={() => handleMediaClick(imageUrl)}
          onLoad={() => setImgLoading(false)}
          onError={() => setImgLoading(false)}
        />

        {/* Fullscreen icon in top-right corner */}
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={() => handleMediaClick(imageUrl)}
            aria-label="fullscreen"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <Fullscreen />
          </IconButton>
        </Box>
        {media_type === 2 && (
          <Box
            sx={{
              position: "absolute",
              top: "43%",
              left: "50%",
              transform: "translate(-50%, -100%)",
            }}
          >
            <IconButton
              onClick={() => handleMediaClick(imageUrl)}
              aria-label="play"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <PlayArrow />
            </IconButton>
          </Box>
        )}

        <CardContent sx={{ padding: "10px" }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: isMobile ? "0.7rem" : "0.8rem",
            }}
            color="text.secondary"
          >
            <AccessTimeIcon
              sx={{
                marginLeft: 2,
                marginRight: 0.5,
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            />
            {formatTimestamp(taken_at)}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "center", paddingTop: "0px" }}>
          <Button
            variant="contained"
            onClick={() => handleDownload(media_type, videoUrl, imageUrl)}
            aria-label="download"
            disabled={downloading}
            sx={{
              width: "80%",
              backgroundColor: downloading ? "#f0f0f0" : "primary.main",
              color: downloading ? "#b0b0b0" : "white",
              "&:hover": {
                backgroundColor: downloading ? "#f0f0f0" : "primary.dark",
              },
            }}
          >
            {downloading ? "Downloading... " : "Download"}
            {!downloading && <DownloadIcon sx={{ fontSize: 30 }} />}
            {downloading && (
              <CircularProgressWithLabel value={downloadProgress} />
            )}
          </Button>
        </CardActions>
      </Card>
      {/* Modal for displaying image or video */}
      <HighlightsModal
        downloading={downloading}
        downloadProgress={downloadProgress}
        open={open}
        setOpen={setOpen}
        handleDownload={() => handleDownload(media_type, videoUrl, imageUrl)}
        currentMediaIndex={currentMediaIndex}
        setCurrentMediaIndex={setCurrentMediaIndex}
        mediaLoading={mediaLoading}
        setMediaLoading={setMediaLoading}
      />
    </div>
  );
};

export default Highlights;
