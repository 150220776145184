import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth="100%"
      style={{ padding: "2rem", backgroundColor: "#1976d2" }}
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ color: "white", textDecoration: "underline 2px " }}
      >
        Our Privacy Policy
      </Typography>

      <Box mt={3}>
        <Typography
          variant="h7"
          gutterBottom
          align="left"
          style={{ color: "white" }}
        >
          This Privacy Policy explains how Asinva collects, uses, and shares
          your personal information while you use the Service. By using the
          Service, you agree to the collection and use of your personal
          information in accordance with the Privacy Policy. This Privacy Policy
          forms part of our Terms of Service. We will review and may update this
          Privacy Policy from time to time. Any changes to this Privacy Policy
          will become effective when we post the revised Privacy Policy on this
          page.
          <br />
          <br />
          Data we collect. Unless stated otherwise in this Privacy Policy, the
          personal data that you provide us will only be stored for as long as
          it is necessary for us to fulfill our obligations in respect of the
          provision of the Service. Asinva processes your personal data only if
          you have provided consent to the processing of such data; or
          processing of the personal data is necessary for the execution of the
          agreement with you; or the relevant personal data are made publicly
          available and are in the public domain; or the processing of the
          personal data should be carried out in accordance with the
          requirements of applicable law, a resolution of a court or a competent
          state or municipal authority.
          <br />
          <br />
          Asinva takes technical, organizational, and other measures to protect
          your personal data from unauthorized or accidental access, collection,
          storage, use, transfer, blocking, or destruction, as well as from
          other illegal actions or accidental leaks. A secure HTTPS connection
          is used, providing a high degree of data security.
          <br />
          <br />
          Purposes of collection. We use the information we collect about you to
          analyze the performance of the Service for marketing and strategic
          development purposes and to maintain, improve or modify the Service,
          target, and personalize advertisements that we display on the Service
          and other websites. We rely on legitimate interests, as described
          above, to process your data. We may also use information provided
          directly by you to correspond with you and address any issues raised
          about the Service.
          <br />
          <br />
          Use of Cookies. We use cookies and similar technologies like web
          beacons, pixel tags, or local shared objects (“flash cookies”), to
          deliver, measure, and improve the Service in various ways. A cookie is
          a small text file that identifies your computer on our server. Cookies
          in themselves do not identify the individual user, only the computer
          being used. Cookies are not used to gather personal information. You
          may configure your computer to accept all cookies, notify you when a
          cookie is issued, and reject any cookies. The way to do this depends
          on the web browser being used (please consult the “Help” function of
          your browser). You may choose to decline all cookies on your computer,
          but this may limit certain areas of the Service. We also work with
          analytics partners who use cookies and similar technologies to help us
          analyze how users use the Service. By using our Service, you agree to
          our use of cookies.
          <br />
          <br />
          Children's protection. If we learn that we’ve collected personal data
          from a child under the age of sixteen (16), we will take reasonable
          steps to delete it. Parents or legal guardians who believe that we may
          have collected personal data from a child can submit a request for
          removal. Please contact us directly at support@Asinva.com.
          <br />
          <br />
          Parties we may share your data with. We may share your personal data
          with our affiliates who may access your personal data to help us
          develop, maintain and provide our Service and help manage our customer
          relationships and service providers who support the Service. We may
          share certain information such as your location, browser and cookie
          data, and other data relating to your use of the Service with our
          business partners to deliver personalized advertisements that may
          interest you. We may also share non-personal data with interested
          third parties to help them understand usage patterns or conduct
          independent research based on anonymous usage data. Links to
          third-party websites or applications within our Service are not
          governed by this Privacy Policy. Check those websites for their
          privacy statements and terms.
          <br />
          <br />
          Rights under GDPR. If you reside in the EEA, under the General Data
          Protection Regulation (“GDPR”), you have the right to access your
          personal information and ask us to rectify, erase, and restrict its
          use. You also have the rights to object to the use of your personal
          information, to ask for the transfer of personal information you have
          provided to us, to withdraw consent, and to restrict our use of
          information for marketing purposes. You may lodge complaints or direct
          questions to the data protection authority. If your personal
          information is transferred, stored, or processed outside the EEA, we
          will take steps to ensure it is treated securely in accordance with
          this Privacy Policy and GDPR.
          <br />
          <br />
          Rights under CCPA. The California Consumer Privacy Act (“CCPA”)
          provides additional rights to California residents to know, delete,
          and opt-out, and requires businesses collecting or disclosing personal
          information to provide notices and means to exercise rights. We do not
          generally sell information as traditionally understood. To the extent
          “sale” under the CCPA includes activities set out in this Privacy
          Policy, we will comply with applicable law. You have the right to know
          certain details about our data practices and the right to delete the
          personal information we have collected from you.
          <br />
          <br />
          Contact information. If you would like to exercise your rights, please
          send your request to contact@Asinva.com. In the request, specify the
          right you wish to exercise and the scope of your request. This email
          may also be used for questions regarding your personal data or about
          this Privacy Policy. Effective December 1, 2024.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
