import { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";

const useDownload = () => {
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleDownload = (media_type, videoUrl, imageUrl) => {
    setDownloading(true);
    setDownloadProgress(0);
    const url =
      media_type === 2
        ? videoUrl
        : `${
            process.env.REACT_APP_API_BASE_URL
          }/fetch-media?mediaUrl=${encodeURIComponent(imageUrl)}`;

    axios
      .get(url, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(progress);
        },
      })
      .then((response) => {
        const extension = media_type === 2 ? "mp4" : "jpg";
        const filename = `${Date.now()}.${extension}`;
        saveAs(response.data, filename);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        console.error("Error downloading the file:", error);
      });
  };

  return { downloading, downloadProgress, handleDownload };
};

export default useDownload;
