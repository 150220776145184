import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
} from "@mui/material";

const HeroSec = ({
  handleInputChange,
  handleSearch,
  username,
  setUsername,
  userInfo,
  error,
  setError,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(); // Call search when Enter is pressed
    }
  };

  return (
    <Box
      component="section"
      sx={{
        minHeight: "100vh",
        minWidth: "100wh",
        backgroundColor: "primary.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        position: "relative",
        overflow: "hidden",
      }}
      id="home"
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
        }}
      />
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Yes! you're in the right place
            </Typography>
            <Typography
              variant="body1"
              align="center"
              paragraph
              sx={{ marginTop: "15px" }}
            >
              Here you can download your favourite person's Instagram posts,
              stories, reels, and highlights
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "18px",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Username"
                value={username}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Listen for the Enter key
                sx={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              />
              <Button
                variant="contained"
                color="success"
                sx={{ height: "56px" }}
                //href="#profile"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroSec;
